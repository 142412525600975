
import ADD_COMMA from "@/assets/utils/add-comma.util";
import VButton from "@/components/shared/button/VButton.vue";
import Card from "@/components/shared/card/Card.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import InputCheckBox from "@/components/shared/input/inputCheckBox.vue";
import Pagination from "@/components/shared/pagination/Pagination.vue";
import VTab from "@/components/shared/tab/VTab.vue";
import stores from "@/stores";
import { __GetCampaignDropdown } from "@/stores/result/action-dto";
import { GetRewardsPayload } from "@/stores/reward/dto";
import IPagination from "@/types/api/pagination.type";
import ICampaignDropdown from "@/types/core/campaign-dropdown.type";
import IReward from "@/types/core/reward.type";
import IUser from "@/types/core/user.type";
import { defineComponent } from "vue";

export default defineComponent({
  name: "RewardsPage",
  components: {
    Card,
    Pagination,
    DatePicker,
    VButton,
    InputCheckBox,
    VTab,
  },
  data() {
    return {
      date: "" as string,
      tabActive: 1 as number,
      rewardsFilter: {
        partnerCode: "",
        dateStart: this.$dayjs().subtract(30, "day").format("YYYY-MM-DD"),
        dateEnd: this.$dayjs().format("YYYY-MM-DD"),
        status: "All",
        sortBy: null,
        sortDictionary: null,
        pagination: {
          length: 10,
          page: 1,
        },
      } as GetRewardsPayload,
      rewards: [] as IReward[],
      debounceTimeAction: null as any,

      /* Tab */
      tabReward: [
        {
          id: "All",
          name: "All",
          status: null,
        },
        {
          id: "Request",
          name: "Wait to Request",
          status: null,
        },
        {
          id: "Pending",
          name: "Pending",
          status: null,
        },
        {
          id: "Paid",
          name: "Paid",
          status: null,
        },
        {
          id: "Void",
          name: "Void",
          status: null,
        },
      ] as { id: string; name: string; status: number | null }[],
      /* IMG SORT SVG */
      arrow_down: require("@/assets/svgs/icons/sort/arrow_down.svg"),
      arrow_up: require("@/assets/svgs/icons/sort/arrow_up.svg"),
      arrow_null: require("@/assets/svgs/icons/sort/arrow.svg"),
      info_circle_solid: require("@/assets/svgs/icons/info-circle-solid.svg"),

      onToggle: false as boolean,

      selected: [] as (string | number)[],
    };
  },
  mounted() {
    const partnerCode = this.user?.partnerCode || "";
    this.rewardsFilter.partnerCode = partnerCode;
    if (partnerCode.length > 0) {
      this.$store.dispatch("getWaitForRequestCount", { partnerCode });
      this.$store.dispatch("getPendingCount", { partnerCode });
    }

    this.tabReward.forEach((item) => {
      if (item.id === "Request") {
        item.status = this.waitForRequestCount;
      } else if (item.id === "Pending") {
        item.status = this.pendingCount;
      }
    });

    /* Get Campaign Dropdown */
    const search = null;
    const payload: __GetCampaignDropdown = { partnerCode, search };
    this.$store.dispatch("getCampaignDropdown", payload);
  },
  methods: {
    addComma: ADD_COMMA,
    sortCampaignName() {
      this.oppositeSortDictionary("CampaignName");
    },
    sortReward() {
      this.oppositeSortDictionary("Reward");
    },
    sortProcessingDate() {
      this.oppositeSortDictionary("ProcessingDate");
    },
    oppositeSortDictionary(sortBy: null | "CampaignName" | "Reward" | "ProcessingDate") {
      const sortByNow = this.rewardsFilter.sortBy;
      const sortDictionaryNow = this.rewardsFilter.sortDictionary;

      if (sortBy !== sortByNow) {
        this.rewardsFilter.sortBy = sortBy;
        this.rewardsFilter.sortDictionary = "DESC";
        return;
      }

      if (sortDictionaryNow) {
        if (sortDictionaryNow === "DESC") {
          this.rewardsFilter.sortDictionary = "ASC";
        } else {
          this.rewardsFilter.sortBy = null;
          this.rewardsFilter.sortDictionary = null;
        }
      } else {
        this.rewardsFilter.sortDictionary = "DESC";
      }
    },
    changeNumberPerPage(e) {
      this.rewardsFilter.pagination = {
        length: Number(e),
        page: 1,
      };
    },
    pageNow(value) {
      if (value > this.pagination.totalPages || value < 1) {
        this.rewardsFilter.pagination.page = 1;
        return;
      }
      this.rewardsFilter.pagination.page = value;
    },
    routingCampaigns(e) {
      this.$store.dispatch("viewCampaignResult", { campaignCode: e });
    },
    onSearch(e) {
      this.rewardsFilter.dateEnd = e.endDate;
      this.rewardsFilter.dateStart = e.startDate;
    },
    onChangeActiveTabs(cur, old) {
      if (cur === old) return;

      this.rewardsFilter.status = cur;
      this.rewardsFilter = {
        ...this.rewardsFilter,
        pagination: {
          length: 10,
          page: 1,
        },
      };
    },
    getStatus(status: string | undefined): string | undefined {
      if (status === null || undefined) return "";
      if (status === "WAIT_FOR_REQUESTING") return "Wait for requesting";
      else if (status === "PAID") return "Paid";
      else if (status === "VOID") return "Void";
      else if (status === "PENDING") return "Pending";
      else return "";
    },
    onDownloadSlip(e: string) {
      window.open(e, "_blank");
    },
    openLinkInNewTab(e) {
      window.open(e, "_blank");
    },

    /* Change Status Reward */
    onChangeStatusRequest(e) {
      const partnerCode = this.user?.partnerCode || "";
      this.$store.dispatch("updateStatusRewards", {
        rewardTransactionMonthlyId: e,
      });
      this.$store.dispatch("getWaitForRequestCount", { partnerCode });
      this.$store.dispatch("getPendingCount", { partnerCode });
      this.rewardsFilter.status = "Pending";
    },
    /* Change Format */
    onChangeFormatDate(e): string {
      return this.$dayjs(e).format("DD/MM/YYYY");
    },

    /* Export Excel */
    DoExportExcelReward() {
      const payload = {
        CampaignCode: this.selected,
        partnerCode: this.rewardsFilter.partnerCode,
        dateStart: this.rewardsFilter.dateStart,
        dateEnd: this.rewardsFilter.dateEnd,
        status: this.rewardsFilter.status,
      };
      this.$store.dispatch("getExcelReward", payload);
    },
    changeLength(e) {
      this.rewardsFilter.pagination = {
        length: Number(e),
        page: 1,
      };
    },
    numberHeader(e): number {
      const headerReward = e + (this.rewardsFilter.pagination.page - 1) * this.rewardsFilter.pagination.length;
      return headerReward;
    },
    onChangeGoalFilter(value: string | number, status: boolean) {
      if (status) {
        this.handleStatusTrue(value);
      } else {
        this.handleStatusFalse(value);
      }

      this.updateRewardsFilter();
    },

    handleStatusTrue(value: string | number) {
      if (value === "All") {
        this.selected = [...this.campaignDropdown.map((item) => item.campaignCode), value];
      } else {
        if (this.selected.includes("All")) return;
        this.selected.push(value);
        const isAllSelected = this.campaignDropdown.length === this.selected.length && !this.selected.includes("All");
        if (isAllSelected) {
          this.selected.push("All");
        }
      }
    },

    handleStatusFalse(value: string | number) {
      if (value === "All" && this.campaignDropdown.length === this.selected.length - 1) {
        this.selected = [];
      } else {
        if (this.selected.includes("All")) {
          this.selected = this.selected.filter((item) => item !== "All");
        }
        this.selected = this.selected.filter((item) => item !== value);
      }
    },

    updateRewardsFilter() {
      this.rewardsFilter = {
        ...this.rewardsFilter,
        CampaignCode: this.selected,
        pagination: {
          ...this.rewardsFilter.pagination,
        },
      };
    },
    // onChangeGoalFilter(value: string | number, status: boolean) {
    //   if (status) {
    //     if (value === "All") {
    //       this.selected = [
    //         ...this.campaignDropdown.map((item) => item.campaignCode),
    //         value,
    //       ];
    //     } else {
    //       if (this.selected.includes("All")) return;
    //       this.selected.push(value);
    //       const isAllSelected =
    //         this.campaignDropdown.length === this.selected.length &&
    //         !this.selected.includes("All");
    //       if (isAllSelected) {
    //         this.selected.push("All");
    //       }
    //     }
    //   } else {
    //     if (
    //       value === "All" &&
    //       this.campaignDropdown.length === this.selected.length - 1
    //     ) {
    //       this.selected = [];
    //     } else {
    //       if (this.selected.includes("All")) {
    //         this.selected = this.selected.filter((item) => item !== "All");
    //       }
    //       this.selected = this.selected.filter((item) => item !== value);
    //     }
    //   }

    //   this.rewardsFilter = {
    //     ...this.rewardsFilter,
    //     CampaignCode: this.selected,
    //     pagination: {
    //       ...this.rewardsFilter.pagination,
    //     },
    //   };
    // },
  },
  computed: {
    user(): IUser | null {
      return this.$store.getters["auth/user"];
    },
    rewardsFromState(): IReward[] {
      return this.$store.getters["rewards"];
    },
    pagination(): IPagination {
      return this.$store.getters["rewardsPagination"];
    },
    isLoadingRewards(): boolean {
      return this.$store.getters["isLoadingRewards"];
    },
    isErrorRewards(): boolean {
      return this.$store.getters["isErrorRewards"];
    },
    errorRewards(): string | null {
      return this.$store.getters["errorRewards"];
    },
    waitForRequestCount(): number {
      return this.$store.getters["waitForRequestCount"];
    },
    pendingCount(): number {
      return this.$store.getters["pendingCount"];
    },
    getWindowsSize() {
      return this.$store.getters["windowsSize"];
    },
    isSuccessRewards(): boolean {
      return this.$store.getters["isSuccessRewards"];
    },
    sortIconCampaignsName() {
      const sortBy = this.rewardsFilter.sortBy;
      const dictionary = this.rewardsFilter.sortDictionary;
      if (sortBy === "CampaignName") {
        if (dictionary === "ASC") return "arrow_up";
        else return "arrow_down";
      }
      return "arrow_null";
    },
    sortIconProcessingDate() {
      const sortBy = this.rewardsFilter.sortBy;
      const dictionary = this.rewardsFilter.sortDictionary;
      if (sortBy === "ProcessingDate") {
        if (dictionary === "ASC") return "arrow_up";
        else return "arrow_down";
      }
      return "arrow_null";
    },
    sortIconReward() {
      const sortBy = this.rewardsFilter.sortBy;
      const dictionary = this.rewardsFilter.sortDictionary;
      if (sortBy === "Reward") {
        if (dictionary === "ASC") return "arrow_up";
        else return "arrow_down";
      }
      return "arrow_null";
    },

    //* Logic Table Loading *//
    isLoadingTableREward(): boolean {
      return this.isLoadingRewards && !this.isErrorRewards;
    },
    isErrorTableReward(): boolean {
      return !this.isLoadingRewards && this.isErrorRewards;
    },
    isNoDataTableReward(): boolean {
      return this.rewards.length <= 0 && !this.isLoadingTableREward && !this.isErrorTableReward;
    },
    campaignDropdown(): ICampaignDropdown[] {
      return this.$store.getters["campaignDropdown"];
    },
  },
  watch: {
    rewardsFromState(cur) {
      this.rewards = cur;
    },
    rewardsFilter: {
      deep: true,
      handler(cur, old) {
        if (!cur.partnerCode) return;
        if (this.debounceTimeAction) clearTimeout(this.debounceTimeAction);

        this.debounceTimeAction = setTimeout(() => {
          stores.dispatch("getRewards", cur);
        }, 500);
      },
    },
    dateStart() {
      this.rewardsFilter = {
        ...this.rewardsFilter,
        pagination: {
          ...this.rewardsFilter.pagination,
          page: 1,
        },
      };
    },
    dateEnd() {
      this.rewardsFilter = {
        ...this.rewardsFilter,
        pagination: {
          ...this.rewardsFilter.pagination,
          page: 1,
        },
      };
    },
    waitForRequestCount(newCount) {
      this.tabReward.find((item) => {
        if (item.id === "Request") {
          item.status = newCount;
        }
      });
    },
    pendingCount(newCount) {
      this.tabReward.find((item) => {
        if (item.id === "Pending") {
          item.status = newCount;
        }
      });
    },
    campaignDropdown: {
      deep: true,
      immediate: true,
      handler(cur) {
        this.selected = ["All"];
        this.onChangeGoalFilter("All", true);
      },
    },
  },
  unmounted() {
    clearTimeout(this.debounceTimeAction);
  },
});
